exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-default-page-template-js": () => import("./../../../src/templates/default-page-template.js" /* webpackChunkName: "component---src-templates-default-page-template-js" */),
  "component---src-templates-homepage-template-js": () => import("./../../../src/templates/homepage-template.js" /* webpackChunkName: "component---src-templates-homepage-template-js" */),
  "component---src-templates-product-page-template-js": () => import("./../../../src/templates/product-page-template.js" /* webpackChunkName: "component---src-templates-product-page-template-js" */),
  "component---src-templates-products-post-template-js": () => import("./../../../src/templates/products-post-template.js" /* webpackChunkName: "component---src-templates-products-post-template-js" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/service-template.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-services-post-template-js": () => import("./../../../src/templates/services-post-template.js" /* webpackChunkName: "component---src-templates-services-post-template-js" */)
}

